<template>
    <div class="page-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="
              page-title-box
              d-sm-flex
              align-items-center
              justify-content-between

            ">
                        <h4 class="mb-sm-0">Transaksi</h4>

                        <div class="page-title-right">
                            <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item active">Riwayat Transaksi</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header align-items-center d-flex">
                            <h4 class="card-title mb-0 flex-grow-1">Riwayat Transaksi</h4>
                        </div>

                        <div class="card-body">
                            <div class="row mb-3">
                                <div class="col-md-6"></div>
                                <div class="col-md-6">
                                    <div class="input-group">
                                        <label for="" class="me-2 mt-2">Rentang Tanggal</label>
                                        <Datepicker v-model="range" range :format="'yyyy-MM-dd'"
                                            @update:modelValue="handleRangeChange" :enableTimePicker="false"
                                            multiCalendars multiCalendarsSolo :partialRange="false" />
                                    </div>
                                </div>
                            </div>
                            <DataTable :url="dataTableUrl" :columns="columnHeaders" :actions="actions"
                                :reload="reloadDataTable" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import DataTable from "../../components/datatable/DataTable.vue";
import { endpoint } from "../../host";
import globalService from '../../services/global-service';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import httpService from '../../services/http-service';

export default {
    components: {
        DataTable,
        Datepicker,
    },

    created() { },

    data() {
        return {
            range: false,
            filter: {
                start: null,
                end: null,
            },
            reloadDataTable: false,
            dataTableUrl: endpoint.transaction,
            actions: [
            ],
            columnHeaders: [
                {
                    text: "Tanggal",
                    render: (data) => {
                        return moment(data.created_at).format('dddd, DD MMMM Y HH:mm')
                    },
                },
                {
                    text: 'Status',
                    render: (data) => {
                        if (data.type == 'debit') {
                            return '<span class="badge badge-outline-success">debit</span>';
                        } else {
                            return '<span class="badge badge-outline-danger">credit</span>';
                        }
                    },
                },
                {
                    text: "Jenis",
                    render: (data) => {
                        if (data['transactionable_type'] == "App\\Models\\ShareDistribution") {
                            return `bagi hasil`;
                        } else if (data['transactionable_type'] == "App\\Models\\Withdraw") {
                            return 'withdraw';
                        } else {
                            return 'kupon belanja';
                        }
                    },
                },
                {
                    text: "Jumlah",
                    render: (data) => {
                        return new Intl.NumberFormat("id-ID", {
                            style: "currency",
                            currency: "IDR",
                        }).format(data.amount);
                    },
                },
                {
                    text: 'Status',
                    render: (data) => {
                        if (data.status == 'Y') {
                            return '<span class="badge badge-outline-success">berhasil</span>';
                        }
                        else if (data.status == 'N') {
                            return '<span class="badge badge-outline-danger">gagal</span>';
                        } else {
                            return '<span class="badge badge-outline-warning">pending</span>';
                        }
                    },
                },
                {
                    text: "Keterangan",
                    render: (data) => {
                        if (data['transactionable_type'] == "App\\Models\\ShareDistribution") {
                            let year = data['transactionable']['report']['year'];
                            let month = globalService.toMonthName(data['transactionable']['report']['month']);
                            return `bagi hasil bulan ${month} ${year}`;
                        } else if (data['transactionable_type'] == "App\\Models\\Withdraw") {
                            let withdraw = data['transactionable'];
                            return `withdraw ke ${withdraw['bank_account_number']} ${withdraw['bank_name']} a/n ${withdraw['bank_account_name']}`;
                        } else {
                            return data['description'];
                        }
                    },
                },
            ],
        };
    },

    methods: {
        handleRangeChange(val) {
            if (val) {
                this.filter.start = moment(val[0]).format('YYYY-MM-DD');
                this.filter.end = moment(val[1]).format('YYYY-MM-DD');
            } else {
                this.filter.start = null;
                this.filter.end = null;
            }
            this.applyFilter();
        },

        applyFilter() {
            this.dataTableUrl = httpService.generateEndpointQuery(endpoint.transaction, this.filter);
            this.reloadDataTable = !this.reloadDataTable;
        }
    }
};
</script>

<style>
</style>